import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { Nullable } from '../types';

type UseToggle<T> = {
  hide: () => void;
  inverse: () => void;
  setValue: Dispatch<SetStateAction<boolean>>;
  show: (stateObject?: T) => void;
  state: Nullable<T>;
  value: boolean;
};

const useToggle = <T>(initialValue = false): UseToggle<T> => {
  const [value, setValue] = useState(initialValue);
  const [state, setState] = useState<Nullable<T>>(null);

  const showToggle = useCallback((stateObject?: T) => {
    setValue(true);
    setState(stateObject ?? null);
  }, []);

  const hideToggle = useCallback(() => {
    setValue(false);
    setState(null);
  }, []);

  const inverseToggle = useCallback(
    (stateObject?: T) => {
      if (value) {
        hideToggle();
        return;
      }
      showToggle(stateObject);
    },
    [hideToggle, showToggle, value],
  );

  return {
    hide: hideToggle,
    inverse: inverseToggle,
    setValue,
    show: showToggle,
    state,
    value,
  } as const;
};

export default useToggle;
