import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonToolbar, FormGroup } from 'reactstrap';
import {
  TextField,
  InputLabel,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import {
  Form,
  Input,
  Feedback as FormFeedback,
} from '@availity/form';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import NotesInput from '../../../shared/components/inputs/NotesInput';
import API from '../../../shared/api/ApiService';
import RequiredLabel from '../../../shared/components/RequiredLabel';

const CreateTicketForm = ({
  ticket, actorId, updateTicket, onSubmit, onCancel,
}) => {
  const [classificationTypes, setClassificationTypes] = useState();
  const [languages, setLanguages] = useState();
  const { t } = useTranslation(['common', 'ticket']);
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  useEffect(() => {
    API.fetchGetTicketClassificationsForActor(actorId)
      .then((result) => {
        result.json()
          .then((data) => setClassificationTypes(data));
      })
      .catch(() => setClassificationTypes([]));

    API.fetchGetLanguages()
      .then((result) => {
        result.json()
          .then((data) => setLanguages(data));
      })
      .catch(() => setLanguages([]));
  }, [actorId]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        requester: ticket.requester,
        escalate: ticket.escalate,
        classificationTypeId: ticket.classificationTypeId ?? '',
        problemDescription: ticket.problemDescription,
      }}
      validationSchema={yup.object().shape({
        requester: yup.string().required(t('errors:fieldIsRequired')),
        phoneNumber: yup.string().matches(phoneRegExp, t('errors:invalidPhoneNumber')),
        email: yup.string().email(t('errors:invalidEmail')),
        classificationTypeId: yup.number().required(t('errors:fieldIsRequired')),
        problemDescription: yup.string().required(t('errors:fieldIsRequired')),
      })}
    >
      <FormGroup>
        <Input
          label={<RequiredLabel translatedName={t('ticket:requestor')} />}
          id="requester"
          name="requester"
          tag={TextField}
          variant="standard"
          fullWidth
          type="text"
          onChange={(e) => updateTicket({ ...ticket, requester: e.target.value })}
        />
        <FormFeedback name="requester" />
      </FormGroup>

      <FormGroup>
        <Input
          label={t('common:email')}
          id="email"
          name="email"
          tag={TextField}
          variant="standard"
          fullWidth
          type="text"
          onChange={(e) => updateTicket({ ...ticket, email: e.target.value })}
        />
        <FormFeedback name="email" />
      </FormGroup>
      <FormGroup>
        <Input
          label={t('common:phone')}
          id="phoneNumber"
          name="phoneNumber"
          tag={TextField}
          variant="standard"
          fullWidth
          type="text"
          onChange={(e) => updateTicket({ ...ticket, phoneNumber: e.target.value })}
        />
        <FormFeedback name="phoneNumber" />
      </FormGroup>
      <FormGroup>
        <Input
          label={t('ticket:apartmentNumber')}
          id="apartmentNumber"
          name="apartmentNumber"
          tag={TextField}
          variant="standard"
          fullWidth
          type="text"
          onChange={(e) => updateTicket({ ...ticket, apartmentNumber: e.target.value })}
        />
        <FormFeedback name="apartmentNumber" />
      </FormGroup>

      {languages && (
        <FormGroup>
          <FormControl fullWidth variant="standard">
            <InputLabel style={{ textAlign: 'left' }}>{t('admin:language')}</InputLabel>
            <Input
              id="language"
              name="language"
              tag={Select}
              fullWidth
              type="select"
              defaultValue={1}
              className="text-left"
              onChange={(e) => updateTicket({ ...ticket, language: e.target.value })}
            >
              {languages.map((c) => <MenuItem className="material-form__option" key={c.id} value={c.id}>{c.description}</MenuItem>)}
            </Input>
            <FormFeedback name="language" />
          </FormControl>
        </FormGroup>
      )}

      <FormGroup style={{ textAlign: 'left' }}>
        <FormControlLabel
          style={{ marginLeft: 0 }}
          control={(
            <Input
              id="escalate"
              name="escalate"
              tag={Switch}
              variant="standard"
              type="checkbox"
              onChange={(e) => updateTicket({ ...ticket, escalate: e.target.checked })}
            />
          )}
          labelPlacement="start"
          label={t('ticket:urgent')}
        />
      </FormGroup>

      {classificationTypes && (
        <FormGroup>
          <FormControl fullWidth variant="standard">
            <InputLabel style={{ textAlign: 'left' }}><RequiredLabel translatedName={t('ticket:classificationType')} /></InputLabel>
            <Input
              id="classificationTypeId"
              name="classificationTypeId"
              tag={Select}
              fullWidth
              type="select"
              className="text-left"
              onChange={(e) => updateTicket({ ...ticket, classificationTypeId: e.target.value })}
            >
              {classificationTypes.map((c) => <MenuItem className="material-form__option" key={c.id} value={c.id}>{c.description}</MenuItem>)}
            </Input>
            <FormFeedback name="classificationTypeId" />
          </FormControl>
        </FormGroup>
      )}

      <FormGroup>
        <InputLabel style={{ textAlign: 'left' }}><RequiredLabel translatedName={t('ticket:problemDescription')} /></InputLabel>
        <Input
          type="textarea"
          name="problemDescription"
          id="problemDescription"
          variant="standard"
          style={{ height: 200 }}
          onChange={(e) => updateTicket({ ...ticket, problemDescription: e.target.value })}
        />
        <FormFeedback name="problemDescription" />
      </FormGroup>

      <FormGroup>
        <NotesInput notes={ticket.notes} setNotes={(notes) => updateTicket({ ...ticket, notes })} />
      </FormGroup>

      <ButtonToolbar className="modal__footer">
        <Button
          outline
          color="secondary"
          type="button"
          size="lg"
          onClick={onCancel}
        >
          <i><FontAwesomeIcon icon={['fad', 'times-circle']} /></i> {t('cancel')}
        </Button>
        <Button
          color="keypoint"
          type="submit"
          size="lg"
        >
          <i><FontAwesomeIcon icon={['fad', 'paper-plane']} /></i> {t('send')}
        </Button>
      </ButtonToolbar>
    </Form>
  );
};

CreateTicketForm.propTypes = {
  actorId: PropTypes.number.isRequired,
  ticket: PropTypes.shape({
    requester: PropTypes.string,
    escalate: PropTypes.bool,
    classificationTypeId: PropTypes.number,
    language: PropTypes.number,
    problemDescription: PropTypes.string,
    notes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      note: PropTypes.string,
    })),
  }),
  updateTicket: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,

};

CreateTicketForm.defaultProps = {
  ticket: {
    requester: '',
    escalate: false,
    classificationTypeId: null,
    problemDescription: '',
    classification: '',
    language: 1,
    notes: [],
  },
};

export default CreateTicketForm;
